.scopus-search {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
  }
  
  .scopus-logo {
    width: 60%;
  }

  /* In your CSS or Sass file */
.custom-pagination-item {
  margin-right: 10px; /* Adjust the value to your desired spacing */
}

  
  .scopus-link {
    cursor: pointer;
    color: #007bff; /* Replace with your primary color's hex code */
  }
  
  .scopus-link:hover {
    text-decoration: underline;
    color: #007bff; /* Replace with your primary color's hex code */
  }
  .scopus-bt {
    background-color: #057cae;
    border-radius: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: white;
    border-style: none;
  }
  
  .scopus-bt:hover {
    color: white;
    background-color: #015071;
  }
  